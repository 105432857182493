import React, { useEffect, useState } from "react";
// import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";

const Niku = ({ value, date }) => {
  //console.log(value);
  //console.log(date);
  const [series, setSeries] = useState([
    {
      name: "Energy",
      data: value,
    },
  ]);

  const [options, setOptions] = useState({
    annotations: {
      points:
        date && date.length
          ? [
              {
                //x: "energies",
                x: date[0],
                categories: date.map((t, i) => (i % 1 === 0 ? t : "")),
                seriesIndex: 0,
                label: {
                  borderColor: "#775DD0",
                  offsetY: 0,
                  style: {
                    color: "#fff",
                    background: "#775DD0",
                  },
                },
              },
            ]
          : [],
    },

    chart: {
      height: 485,
      type: "bar",
      animations: {
        enabled: false,
        easing: "easeinout",
        speed: 800,
        delay: 200,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        columnWidth: "70%",
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      width: 2,
    },

    grid: {
      row: {
        colors: ["transparent", "#b4a7d6"],
      },
      markings: function (axes) {
        var markings = [];
        var xaxis = axes.xaxis;
        for (
          var x = Math.floor(xaxis.min);
          x < xaxis.max;
          x += xaxis.tickSize * 2
        ) {
          markings.push({
            xaxis: { from: x, to: x + xaxis.tickSize },
            color: "rgba(0, 0, 0, 0.05)",
          });
        }
        return markings;
      },
      tickColor: "#ccc",
      labelMargin: 10,
      axisMargin: 15,
      minBorderMargin: 5,
    },
    xaxis: {
      labels: {
        rotate: -45,
      },
      //   categories: date ? date.map((t, i) => (i % 1 === 0 ? t : "")) : [],
      categories: date,
      //   tickPlacement: "on",
    },
    yaxis: {
      title: {
        text: "Energy Consumption(Units)",
        data: value,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [50, 0, 100],
      },
    },
  });

  const handleResize = () => {
    const chart = document.querySelector("#basic-bar");
    if (chart) {
      const parentWidth = chart.parentElement.offsetWidth;
      const width = parentWidth > 500 ? 500 : parentWidth;
      chart.style.width = `${width}px`;
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div id="chart">
      <Chart
        key={`${value}-${date}`}
        options={options}
        series={series}
        type="bar"
        height={485}
      />
    </div>
  );
};

export default Niku;
