import React from "react";
import axios from "axios";

const Login = () => {
  let loginToken = "";
  axios
    .post("https:///vidani.smart-iot.in/api/super-admin/login", {
      username: "Sandeep",
      password: "Sandeep@123",
      remember: true,
      domain: "vidani",
    })
    .then(function (response) {
      console.log(response.data.message);
      loginToken = response.data.data;
      console.log(loginToken);
      loginToken = localStorage.setItem(
        "loginToken",
        JSON.stringify(loginToken)
      );
    })
    .catch(function (error) {
      console.log(error);
    });

  return <div></div>;
};

export default Login;
