import React, { useState, useEffect } from "react";
import Best from "./Best";
import Line from "./Line";
import axios from "axios";
import Login from "./Login";
import Basicline from "./Basicline";
import Barchart from "./Barchart";
import Basicbar from "./Basicbar";
import Columns from "./Columns";
import Niku from "./Niku";
import Decrypt from "./Decrypt";
import Dec2bin from "./Dec2bin";
import EnergyBar from "./EnergyBar";
import Barfinal from "./BarFinal";
// import Mixed from "./Mixed";

const StreetLight1 = () => {
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  let [imeiArr, setImeiArr] = useState([]);
  let [dataArr, setDataArr] = useState([]);
  let [vl1, setVl1] = useState();
  let [vl2, setVl2] = useState();
  let [vl3, setVl3] = useState();
  let [il1, setIL1] = useState();
  let [il2, setIL2] = useState();
  let [il3, setIL3] = useState();
  let [pow, setPow] = useState();
  let [energy, setEnergy] = useState();
  let [messageArray, setMessageArray] = useState([]);
  let [timeArray, setTimeArray] = useState([]);
  let [relay1valueArr, setRelay1valueArr] = useState([]);
  let [relay1TimeArr, setRelay1timeArr] = useState([]);
  let [relay2TimeArr, setRelay2timeArr] = useState([]);
  let [relay2valueArr, setRelay2valueArr] = useState([]);
  let [relay3TimeArr, setRelay3timeArr] = useState([]);
  let [relay3valueArr, setRelay3valueArr] = useState([]);
  let [iptimeArr, setIPtimeArr] = useState([]);
  let [ipvalueArr, setIPvalueArr] = useState([]);
  let [optimeArr, setOPtimeArr] = useState([]);
  let [opvalueArr, setOPvalueArr] = useState([]);
  let [doortimeArr, setDOortimeArr] = useState([]);
  let [doorvalueArr, setDoorvalueArr] = useState([]);
  let [energyPerDay, setEnergyPerDay] = useState([]);
  let [energyPerDayDate, setEnergyPerDayDate] = useState([]);
  let [powerArr, setPowerArr] = useState([]);
  let [energyperMinute, setEnergyPerMinute] = useState([]);
  let [energydiff, setEnergyDiff] = useState([]);
  let [timediff, setTimediff] = useState([]);
  let [analytics, setAnalytics] = useState([]);
  let energyConsumedDay;

  let loginToken = JSON.parse(localStorage.getItem("loginToken"));
  console.log(loginToken);

  const handleChange = (event) => {
    setSelectedDate(event.target.value);
  };

  function stringToArray(str) {
    const arr = str.split(",");
    return arr;
  }

  function convertTo24Hour(timeString) {
    const time = new Date("1970-01-01 " + timeString);
    let hours = time.getHours();
    const isPM = hours >= 12;
    // Convert to 24-hour format
    hours = (hours % 12) + (isPM ? 12 : 0);

    // Format the time string
    const minutes = time.getMinutes().toString().padStart(2, "0");
    const seconds = time.getSeconds().toString().padStart(2, "0");
    const time24 =
      hours.toString().padStart(2, "0") + ":" + minutes + ":" + seconds;

    return time24;
  }

  const headers = {
    Authorization: `Bearer ${loginToken}`,
    "Content-Type": "application/json",
  };

  const data = {
    device_id: 25,
    // date: "2023-03-22",
    date: selectedDate,
  };

  // console.log(headers);
  // energyPerDay = [];
  //energyPerDayDate = [];

  //   get request for monthly energy consumption
  axios
    .get(
      `https://super-admin.smart-iot.in/api/super-admin/report/getreportdata/14/2023-03-23`,
      { headers }
    )
    .then(function (response) {
      //console.log(response.data);
      let param = response.data.data;
      // console.log(param.length);
      for (let i = 0; i < param.length; i++) {
        //console.log(param[i].param_20);
        //console.log(param[i].date);

        energyPerDay[i] = param[i].param_20;
        energyPerDayDate[i] = param[i].date.substring(0, 10);
      }
      // setEnergyPerDay(energyPerDay);
      // setEnergyPerDayDate(energyPerDayDate);
    });
  console.log(energyPerDayDate.length);
  let elen = energyPerDayDate.length;
  // console.log(energyPerDayDate[elen - 1]);

  // console.log(energyPerDay.length);

  for (let i = 0; i < energyPerDay.length; i++) {
    if (energyPerDay[i] == null) {
      // console.log("reached");
      // console.log(energyPerDay[i]);
      energyPerDay[i] = 0;
    }
    // energyPerDay[i] = energyPerDay[i].toFixed(0);
    // energyPerDay[energyPerDay.length - 1] =
    // energyPerDay[energyPerDay.length - 1].toFixed(2);
  }

  console.log(energyPerDay);
  console.log(energyPerDayDate);
  console.log(energyPerDayDate[0]);
  if (energyPerDay) {
    localStorage.setItem("energyPerDay", JSON.stringify(energyPerDay));
  }

  if (energyPerDayDate) {
    localStorage.setItem("energyPerDayDate", JSON.stringify(energyPerDayDate));
  }

  axios
    .post(
      "https://vidani.smart-iot.in/api/super-admin/device/getdevicelogs",
      data,
      { headers }
    )
    .then(function (response) {
      let resdata = response.data.data;
      let loop = resdata.logs;
      // console.log(loop);
      //   console.log(loop.length);

      messageArray = [];
      timeArray = [];
      let relay1array = [];
      let relay2array = [];
      let relay3array = [];
      let inputmcbArr = [];
      let outputmcbArr = [];
      let doorArr = [];
      powerArr = [];
      energyperMinute = [];
      energydiff = [];
      timediff = [];

      for (let i = 0; i < loop.length; i++) {
        // console.log(resdata.logs[i].msg);
        messageArray.push(stringToArray(resdata.logs[i].msg));

        const timelogs = new Date(resdata.logs[i].log_time).toLocaleTimeString(
          [],
          {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZone: "Asia/Kolkata",
          }
        );

        // timeArray.push(convertTo24Hour(timelogs));
        timeArray.push(convertTo24Hour(timelogs));
      }
      // console.log(messageArray);
      if (timeArray) {
        localStorage.setItem("timeArray", JSON.stringify(timeArray));
      }
      // console.log(timeArray);

      for (let i = 0; i < messageArray.length; i++) {}

      for (let i = 0; i < messageArray.length; i++) {
        let relay1 =
          Number(messageArray[i][502]) * 256 + Number(messageArray[i][503]);

        let relay2 =
          Number(messageArray[i][504]) * 256 + Number(messageArray[i][505]);

        let relay3 =
          Number(messageArray[i][506]) * 256 + Number(messageArray[i][507]);

        let inputmcb =
          Number(messageArray[i][500]) * 256 + Number(messageArray[i][501]);

        let outputmcb =
          Number(messageArray[i][508]) * 256 + Number(messageArray[i][509]);

        let doorval =
          Number(messageArray[i][510]) * 256 + Number(messageArray[i][511]);
        // console.log(relay1);
        if (relay1 < 500) {
          relay1 = 100; //off
        } else {
          relay1 = 102; //on
        }
        relay1array.push(relay1);

        if (relay2 < 500) {
          relay2 = 100;
        } else {
          relay2 = 102;
        }
        relay2array.push(relay2);

        if (relay3 < 500) {
          relay3 = 100;
        } else {
          relay3 = 102;
        }
        relay3array.push(relay3);

        if (inputmcb < 500) {
          inputmcb = 100;
        } else {
          inputmcb = 102;
        }
        inputmcbArr.push(inputmcb);

        if (outputmcb < 500) {
          outputmcb = 100;
        } else {
          outputmcb = 102;
        }
        outputmcbArr.push(outputmcb);

        if (doorval < 500) {
          doorval = 100;
        } else {
          doorval = 102;
        }
        doorArr.push(doorval);

        // power calculation starts here

        let result = [];
        let st = Number(messageArray[i][24]);
        let nd = Number(messageArray[i][25]);
        let rd = Number(messageArray[i][26]);
        let th = Number(messageArray[i][27]);

        Number(
          result.push(
            Decrypt(Dec2bin((rd * 256 + th) * 65536 + (st * 256 + nd)))
          )
        ).toFixed(2);

        let resultdata = Number(result[0]).toFixed(2);
        powerArr.push(resultdata);

        //power calculation ends here

        /**energy calculation starts here**/
        let result2 = [];
        st = Number(messageArray[i][40]);
        nd = Number(messageArray[i][41]);
        rd = Number(messageArray[i][42]);
        th = Number(messageArray[i][43]);

        Number(
          result2.push(
            Decrypt(Dec2bin((rd * 256 + th) * 65536 + (st * 256 + nd)))
          )
        ).toFixed(2);

        let resultenergy = Number(result2[0]).toFixed(2);
        energyperMinute.push(resultenergy);

        /**energy calculation ends here**/
      }
      console.log(energyperMinute);
      for (let i = 0; i < energyperMinute.length; i++) {
        if (energyperMinute[i] === "NaN") {
          console.log(energyperMinute[i], i);
          energyperMinute[i] = 0;
        }
      }
      // console.log(powerArr);

      for (let i = 0; i < energyperMinute.length / 60; i++) {
        let index = i * 60;
        energydiff.push(
          (energyperMinute[index + 60] - energyperMinute[index]).toFixed(2)
        );

        timediff.push(timeArray[index]);

        if (energydiff[i] === "NaN") {
          energydiff[i] = "0";
        }
      }

      //calculation for total energy consumed in a day
      // console.log(energyperMinute);
      // let nonzeroEnergy = energyperMinute.filter((value) => value !== 0);
      // let minEnergy = Number(Math.min(...nonzeroEnergy));
      // let maxEnergy = Number(Math.max(...nonzeroEnergy));
      // console.log(minEnergy, maxEnergy);
      // energyConsumedDay = (maxEnergy - minEnergy).toFixed(2);
      // console.log(energyConsumedDay);

      // if (energyConsumedDay) {
      //   localStorage.setItem(
      //     "energyConsumedDay",
      //     JSON.stringify(energyConsumedDay)
      //   );
      // }

      // for (let i = 0; i < energyperMinute; i++) {}
      //calculation ends here

      console.log(energydiff);
      console.log(timediff);

      console.log(powerArr);
      if (powerArr) {
        localStorage.setItem("powerArr", JSON.stringify(powerArr));
      }
      if (energyperMinute) {
        localStorage.setItem(
          "energyperMinute",
          JSON.stringify(energyperMinute)
        );
      }

      if (energydiff) {
        localStorage.setItem("energydiff", JSON.stringify(energydiff));
      }

      if (timediff) {
        localStorage.setItem("timediff", JSON.stringify(timediff));
      }

      // console.log(relay1array);
      // console.log(relay2array);
      // console.log(relay3array);
      // console.log(inputmcbArr);
      // console.log(outputmcbArr);
      // console.log(doorArr);

      relay1valueArr = [];
      relay1TimeArr = [];

      relay2valueArr = [];
      relay2TimeArr = [];

      relay3TimeArr = [];
      relay3valueArr = [];

      iptimeArr = [];
      ipvalueArr = [];

      optimeArr = [];
      opvalueArr = [];

      doortimeArr = [];
      doorvalueArr = [];

      for (let i = 0; i < messageArray.length / 30; i++) {
        for (let j = 0; j < 30; j++) {
          let trueIndex = i * 30 + j;

          if (
            (relay1array[trueIndex] === 102 &&
              relay1array[trueIndex - 1] === 100) ||
            (relay1array[trueIndex] === 100 &&
              relay1array[trueIndex - 1] === 102)
          ) {
            relay1valueArr.push(relay1array[trueIndex]);
            relay1TimeArr.push(timeArray[trueIndex]);
          }

          if (
            (relay2array[trueIndex] === 102 &&
              relay2array[trueIndex - 1] === 100) ||
            (relay2array[trueIndex] === 100 &&
              relay2array[trueIndex - 1] === 102)
          ) {
            relay2valueArr.push(relay2array[trueIndex]);
            relay2TimeArr.push(timeArray[trueIndex]);
          }

          if (
            (relay3array[trueIndex] === 102 &&
              relay3array[trueIndex - 1] === 100) ||
            (relay3array[trueIndex] === 100 &&
              relay3array[trueIndex - 1] === 102)
          ) {
            relay3valueArr.push(relay3array[trueIndex]);
            relay3TimeArr.push(timeArray[trueIndex]);
          }

          if (
            (inputmcbArr[trueIndex] === 102 &&
              inputmcbArr[trueIndex - 1] === 100) ||
            (inputmcbArr[trueIndex] === 100 &&
              inputmcbArr[trueIndex - 1] === 102)
          ) {
            ipvalueArr.push(inputmcbArr[trueIndex]);
            iptimeArr.push(timeArray[trueIndex]);
          }

          if (
            (outputmcbArr[trueIndex] === 102 &&
              outputmcbArr[trueIndex - 1] === 100) ||
            (outputmcbArr[trueIndex] === 100 &&
              outputmcbArr[trueIndex - 1] === 102)
          ) {
            opvalueArr.push(inputmcbArr[trueIndex]);
            optimeArr.push(timeArray[trueIndex]);
          }

          if (
            (doorArr[trueIndex] === 102 && doorArr[trueIndex - 1] === 100) ||
            (doorArr[trueIndex] === 100 && doorArr[trueIndex - 1] === 102)
          ) {
            doorvalueArr.push(doorArr[trueIndex]);
            doortimeArr.push(timeArray[trueIndex]);
          }
        }
        let trueIndex = i * 30 + 30;
        relay1valueArr.push(relay1array[trueIndex]);
        relay1TimeArr.push(timeArray[trueIndex]);

        relay2valueArr.push(relay2array[trueIndex]);
        relay2TimeArr.push(timeArray[trueIndex]);

        relay3valueArr.push(relay3array[trueIndex]);
        relay3TimeArr.push(timeArray[trueIndex]);

        ipvalueArr.push(inputmcbArr[trueIndex]);
        iptimeArr.push(timeArray[trueIndex]);

        opvalueArr.push(outputmcbArr[trueIndex]);
        optimeArr.push(timeArray[trueIndex]);

        doorvalueArr.push(doorArr[trueIndex]);
        doortimeArr.push(timeArray[trueIndex]);
      }

      console.log("1", relay1TimeArr);
      console.log("1", relay1valueArr);

      console.log("2", relay2TimeArr);
      console.log("2", relay2valueArr);

      console.log("3", relay3TimeArr);
      console.log("3", relay3valueArr);

      console.log("ip", iptimeArr);
      console.log("ip", ipvalueArr);

      console.log("op", optimeArr);
      console.log("op", opvalueArr);

      console.log("door", doortimeArr);
      console.log("door", doorvalueArr);

      if (relay1valueArr) {
        localStorage.setItem("relay1valueArr", JSON.stringify(relay1valueArr));
      }

      if (relay1TimeArr) {
        localStorage.setItem("relay1TimeArr", JSON.stringify(relay1TimeArr));
      }

      if (relay2TimeArr) {
        localStorage.setItem("relay2TimeArr", JSON.stringify(relay2TimeArr));
      }
      if (relay2valueArr) {
        localStorage.setItem("relay2valueArr", JSON.stringify(relay2valueArr));
      }

      if (relay3TimeArr) {
        localStorage.setItem("relay3TimeArr", JSON.stringify(relay3TimeArr));
      }

      if (relay3valueArr) {
        localStorage.setItem("relay3valueArr", JSON.stringify(relay3valueArr));
      }

      if (iptimeArr) {
        localStorage.setItem("iptimeArr", JSON.stringify(iptimeArr));
      }

      if (ipvalueArr) {
        localStorage.setItem("ipvalueArr", JSON.stringify(ipvalueArr));
      }

      if (optimeArr) {
        localStorage.setItem("optimeArr", JSON.stringify(optimeArr));
      }

      if (opvalueArr) {
        localStorage.setItem("opvalueArr", JSON.stringify(opvalueArr));
      }

      if (doortimeArr) {
        localStorage.setItem("doortimeArr", JSON.stringify(doortimeArr));
      }

      if (doorvalueArr) {
        localStorage.setItem("doorvalueArr", JSON.stringify(doorvalueArr));
      }

      //   console.log(relay1array);
    })
    .catch(function (error) {
      console.log(error);
    });

  // for (let i = 0; i < elen; i++) {
  //   if (i < elen - 1) {
  //     console.log(energyPerDay[i]);
  //   } else {
  //     energyPerDay[elen - 1] = energyConsumedDay;
  //     console.log(energyConsumedDay);
  //   }

  //   if (energyPerDay[i] == null) {
  //     energyPerDay[i] = 0;
  //   }
  // }

  // const updateData = () => {
  //   const imeiArr = JSON.parse(localStorage.getItem("imeiArr")) || [];
  //   const dataArr = JSON.parse(localStorage.getItem("dataArr")) || [];

  //   const vl1 = dataArr[5] ? dataArr[5][0] : "0";
  //   const vl2 = dataArr[5] ? dataArr[5][1] : "0";
  //   const vl3 = dataArr[5] ? dataArr[5][2] : "0";
  //   const il1 = dataArr[5] ? dataArr[5][3] : "0";
  //   const il2 = dataArr[5] ? dataArr[5][4] : "0";
  //   const il3 = dataArr[5] ? dataArr[5][5] : "0";
  //   const pow = dataArr[5] ? dataArr[5][6] : "0";
  //   const energy = dataArr[5] ? dataArr[5][10] : "0";

  //   setImeiArr(imeiArr);
  //   setDataArr(dataArr);
  //   setVl1(vl1);
  //   setVl2(vl2);
  //   setVl3(vl3);
  //   setIL1(il1);
  //   setIL2(il2);
  //   setIL3(il3);
  //   setPow(pow);
  //   setEnergy(energy);
  //   setInterval(updateData, 5000);
  // };
  // let intervalId = setInterval(updateData, 5000);

  useEffect(() => {
    const updateData = () => {
      const imeiArr = JSON.parse(localStorage.getItem("imeiArr")) || [];
      const dataArr = JSON.parse(localStorage.getItem("dataArr")) || [];
      const vl1 = dataArr[5] ? dataArr[5][0] : "0";
      const vl2 = dataArr[5] ? dataArr[5][1] : "0";
      const vl3 = dataArr[5] ? dataArr[5][2] : "0";
      const il1 = dataArr[5] ? dataArr[5][3] : "0";
      const il2 = dataArr[5] ? dataArr[5][4] : "0";
      const il3 = dataArr[5] ? dataArr[5][5] : "0";
      const pow = dataArr[5] ? dataArr[5][6] : "0";
      const energy = dataArr[5] ? dataArr[5][10] : "0";
      setImeiArr(imeiArr);
      setDataArr(dataArr);
      setVl1(vl1);
      setVl2(vl2);
      setVl3(vl3);
      setIL1(il1);
      setIL2(il2);
      setIL3(il3);
      setPow(pow);
      setEnergy(energy);

      // console.log(vl1);
    };
    updateData();
    const intervalId = setInterval(updateData, 6000);
    // clearInterval(intervalId);
    return () => clearInterval(intervalId);
  }, []);

  // updateData();

  // clearInterval(intervalId);
  // console.log(relay1TimeArr);
  // console.log(selectedDate.getYear());

  relay1TimeArr = JSON.parse(localStorage.getItem("relay1TimeArr")) || [];
  relay1valueArr = JSON.parse(localStorage.getItem("relay1valueArr")) || [];

  relay2TimeArr = JSON.parse(localStorage.getItem("relay2TimeArr")) || [];
  relay2valueArr = JSON.parse(localStorage.getItem("relay2valueArr")) || [];

  relay3TimeArr = JSON.parse(localStorage.getItem("relay3TimeArr")) || [];
  relay3valueArr = JSON.parse(localStorage.getItem("relay3valueArr")) || [];

  iptimeArr = JSON.parse(localStorage.getItem("iptimeArr")) || [];
  ipvalueArr = JSON.parse(localStorage.getItem("ipvalueArr")) || [];

  optimeArr = JSON.parse(localStorage.getItem("optimeArr")) || [];
  opvalueArr = JSON.parse(localStorage.getItem("opvalueArr")) || [];

  doortimeArr = JSON.parse(localStorage.getItem("doortimeArr")) || [];
  doorvalueArr = JSON.parse(localStorage.getItem("doorvalueArr")) || [];

  powerArr = JSON.parse(localStorage.getItem("powerArr")) || [];
  timeArray = JSON.parse(localStorage.getItem("timeArray")) || [];

  energyperMinute = JSON.parse(localStorage.getItem("energyperMinute")) || [];
  energydiff = JSON.parse(localStorage.getItem("energydiff")) || [];
  timediff = JSON.parse(localStorage.getItem("timediff")) || [];

  console.log(energyperMinute);
  let nonzeroEnergy = energyperMinute.filter((value) => value !== 0);
  console.log(nonzeroEnergy);
  let minEnergy = Math.min(...nonzeroEnergy);
  let maxEnergy = Math.max(...nonzeroEnergy);
  console.log(minEnergy, maxEnergy);
  energyConsumedDay = (maxEnergy - minEnergy).toFixed(2);
  console.log(energyConsumedDay);

  if (energyConsumedDay) {
    localStorage.setItem(
      "energyConsumedDay",
      JSON.stringify(energyConsumedDay)
    );
  }

  energyConsumedDay = JSON.parse(localStorage.getItem("energyConsumedDay"));
  console.log(energyConsumedDay);

  let totalActiveLight = 100;
  let totalFaultyLight = 20;
  let totalEnergySaved = (80 - energyConsumedDay).toFixed(2);
  let NumberOfAlerts = 0;
  let totalMoneySaved = (totalEnergySaved * 10).toFixed(2);
  console.log(totalMoneySaved);

  let Active = 0;
  let breakdown = 0;

  for (let i = 0; i < powerArr.length; i++) {
    if (powerArr[i] === 0) {
      breakdown++;
    } else {
      Active++;
    }
  }
  console.log(Active, breakdown);

  // console.log(powerArr);
  // console.log(timeArray);

  // console.log(relay1TimeArr);
  // console.log(relay1valueArr);

  return (
    <div className="">
      <Login />
      {/* navigation */}
      <div className="bg-gray-200 2xl:h-1/6 p-3 border-b-2 border-gray-500 shadow-indigo-500/30 shadow-lg">
        <div className="flex flex-wrap">
          {/* <div className=" flex-1 font-bold text-xl text-center text-black flex flex-wrap justify-center items-center">
            STREET LIGHT ANALYTICS
          </div> */}
          {/* <div className="flex flex-wrap justify-end">
            <button>Home</button>
            <button>xyz</button>
          </div> */}
          <form className="flex flex-1 flex-col justify-end sm:flex-row sm:gap-4">
            <label htmlFor="datepicker" className="sr-only">
              Select Date
            </label>
            <input
              type="date"
              id="datepicker"
              // defaultValue={date}
              value={selectedDate}
              // value={}
              onChange={handleChange}
              className="px-3 py-2 m-1 border rounded-lg sm:w-52 lg:w-56 md:w-60 xl:w-60 2xl:w-60"
            />
          </form>
        </div>
      </div>
      {/* flex box for voltages currents and pow energy */}
      <div className="flex flex-wrap">
        {/* vl1 vl2 vl3 */}
        <div className="flex-1 bg-gray-200 2xl:h-1/6 p-5  shadow-indigo-500/50 m-2 rounded-lg shadow-lg flex flex-wrap justify-center items-center">
          <div className="flex-1 p-4 m-2 rounded-lg bg-red-500 hover:bg-red-700 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30 flex flex-col">
            <div className="text-center">VL1</div>
            <div className="text-center">{vl1}</div>
          </div>
          <div className="flex-1 p-4 m-2 rounded-lg bg-yellow-500 hover:bg-yellow-600 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30 flex flex-col">
            <div className="text-center">VL2</div>
            <div className="text-center">{vl2}</div>
          </div>
          <div className="flex-1 p-4 m-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30 flex flex-col">
            <div className="text-center">VL3</div>
            <div className="text-center">{vl3}</div>
          </div>
        </div>

        {/* il1 il2 il3  */}
        <div className="flex-1 bg-gray-200 2xl:h-1/6 p-5  shadow-indigo-500/50 m-2 rounded-lg shadow-lg flex flex-wrap justify-center items-center">
          <div className="flex-1 p-4 m-2 rounded-lg bg-red-500 hover:bg-red-700 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30 flex flex-col">
            <div className="text-center">IL1</div>
            <div className="text-center">{il1}</div>
          </div>
          <div className="flex-1 p-4 m-2 rounded-lg bg-yellow-500 hover:bg-yellow-600 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30 flex flex-col">
            <div className="text-center">IL2</div>
            <div className="text-center">{il2}</div>
          </div>
          <div className="flex-1 p-4 m-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30 flex flex-col">
            <div className="text-center">IL3</div>
            <div className="text-center">{il3}</div>
          </div>
        </div>

        {/* power energy */}
        <div className="flex-1 bg-gray-200 2xl:h-1/6 p-5  shadow-indigo-500/50 m-2 rounded-lg shadow-lg flex flex-wrap justify-center align-middle">
          <div className="flex-1 p-4 m-2 rounded-lg bg-green-600 hover:bg-green-700 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30 flex flex-col">
            <div className="flex-1 text-center">Power</div>
            <div className="flex-1 text-center">{pow}Kw</div>
          </div>
          <div className="flex-1 p-4 m-2 rounded-lg bg-green-600 hover:bg-green-700 text-black hover:text-white font-bold shadow-lg shadow-blue-500/30 flex flex-col">
            <div className="flex-1 text-center">Energy</div>
            <div className="flex-1 text-center">{energy}Kwh</div>
          </div>
        </div>
      </div>
      {/* relay 1 2 3 flex */}
      <div className="flex flex-wrap">
        <div className="flex-1 bg-gray-200 2xl:h-1/6 p-5  shadow-indigo-500/50 border-4 border-red-500 m-2 rounded-lg shadow-lg ease-in-out transform hover:scale-105 transition duration-200">
          <div className="flex flex-col">
            <h1 className="text-center p-2 font-mono text-xl border-b-2 border-red-400">
              CONTACTOR-1
            </h1>
            <h1 className="text-center p-2 font-mono">12:00AM - 12:00PM</h1>
          </div>

          <Best timeArr={relay1TimeArr} valueArr={relay1valueArr} />
        </div>
        <div className="flex-1 bg-gray-200 2xl:h-1/6 p-5  shadow-indigo-500/50 border-4 border-yellow-400 m-2 rounded-lg shadow-lg ease-in-out transform hover:scale-105 transition duration-200">
          <div className="flex flex-col">
            <h1 className="text-center p-2 font-mono text-xl border-b-2 border-yellow-400">
              CONTACTOR-2
            </h1>
            <h1 className="text-center p-2 font-mono">12:00AM - 12:00PM</h1>
          </div>
          <Best timeArr={relay2TimeArr} valueArr={relay2valueArr} />
        </div>
        <div className="flex-1 bg-gray-200 2xl:h-1/6 p-5  shadow-indigo-500/50 border-4 border-blue-500 m-2 rounded-lg shadow-lg ease-in-out transform hover:scale-105 transition duration-200">
          <div className="flex flex-col">
            <h1 className="text-center p-2 font-mono text-xl border-b-2 border-blue-500">
              CONTACTOR-3
            </h1>
            <h1 className="text-center p-2 font-mono">12:00AM - 12:00PM</h1>
          </div>
          <Best timeArr={relay3TimeArr} valueArr={relay3valueArr} />
        </div>
      </div>

      {/* o/pmcb i/pmcb door flex */}
      <div className="flex flex-wrap">
        <div className="flex-1 bg-gray-200 2xl:h-1/6 p-5 border-4 border-green-600 shadow-indigo-500/50 m-2 rounded-lg shadow-lg ease-in-out transform hover:scale-105 transition duration-200">
          <div className="flex flex-col">
            <h1 className="text-center p-2 font-mono text-xl border-b-2 border-green-600">
              I/P MCB
            </h1>
            <h1 className="text-center p-2 font-mono">12:00AM - 12:00PM</h1>
          </div>
          <Best timeArr={iptimeArr} valueArr={ipvalueArr} />
        </div>
        <div className="flex-1 bg-gray-200 2xl:h-1/6 p-5 border-4 border-green-600  shadow-indigo-500/50 m-2 rounded-lg shadow-lg ease-in-out transform hover:scale-105 transition duration-200">
          <div className="flex flex-col">
            <h1 className="text-center p-2 font-mono text-xl border-b-2 border-green-600">
              O/P MCB
            </h1>
            <h1 className="text-center p-2 font-mono">12:00AM - 12:00PM</h1>
          </div>
          <Best timeArr={optimeArr} valueArr={opvalueArr} />
        </div>
        <div className="flex-1 bg-gray-200 2xl:h-1/6 p-5 border-4 border-green-600  shadow-indigo-500/50 m-2 rounded-lg shadow-lg ease-in-out transform hover:scale-105 transition duration-200">
          <div className="flex flex-col">
            <h1 className="text-center p-2 font-mono text-xl border-b-2 border-green-600">
              DOOR
            </h1>
            <h1 className="text-center p-2 font-mono">12:00AM - 12:00PM</h1>
          </div>
          <Best timeArr={doortimeArr} valueArr={doorvalueArr} />
        </div>
      </div>

      {/* energy/month enrgy/day flexbox */}
      <div className="flex flex-wrap">
        <div className="flex-1 bg-gray-200 2xl:h-1/6 p-5 border-4 border-blue-600 shadow-indigo-500/50 m-2 rounded-lg shadow-lg">
          <div className="flex flex-col">
            <h1 className="text-center p-2 font-mono text-xl border-b-2 border-blue-600 m-2 mb-4">
              STREET LIGHT ANALYTICS
            </h1>
            {/* <h1 className="text-center p-2 font-mono">YEAR</h1> */}
          </div>
          {/* buttons starts here */}
          <div className="flex flex-wrap justify-center items-center">
            <div className="flex flex-col justify-center align-middle">
              <button
                type="button"
                className="flex-1 text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-3 text-center mr-2 mb-2 my-1"
              >
                Today's energy consumption : {energyConsumedDay}Kwh
              </button>
              <button
                type="button"
                className=" flex flex-1 text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg text-sm px-5 py-3 text-center mr-2 mb-2 my-1"
              >
                Total energy saved : {totalEnergySaved} Units
              </button>
              <button
                type="button"
                className=" flex text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 shadow-lg shadow-cyan-500/50 dark:shadow-lg dark:shadow-cyan-800/80 font-medium rounded-lg text-sm px-5 py-3 text-center mr-2 mb-2 my-1"
              >
                Total number of active lights:{" "}
                {totalActiveLight - totalFaultyLight}
              </button>
              <button
                type="button"
                className="text-white flex bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-5 py-3 text-center mr-2 mb-2 my-1"
              >
                Total number of faulty lights: {totalFaultyLight}
              </button>
              <button
                type="button"
                className="text-gray-900 flex bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-lime-300 dark:focus:ring-lime-800 shadow-lg shadow-lime-500/50 dark:shadow-lg dark:shadow-lime-800/80 font-medium rounded-lg text-sm px-5 py-3 text-center mr-2 mb-2 my-1"
              >
                Number of alerts: {NumberOfAlerts}
              </button>
              <button
                type="button"
                className="text-white flex bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-3 text-center mr-2 mb-2 my-1"
              >
                Total money saved : {totalMoneySaved}
              </button>
              <button
                type="button"
                className="text-white flex bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 shadow-lg shadow-pink-500/50 dark:shadow-lg dark:shadow-pink-800/80 font-medium rounded-lg text-sm px-5 py-3 text-center mr-2 mb-2 my-1"
              >
                Active time : {Active}
              </button>
              <button
                type="button"
                className="text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-purple-800/80 font-medium rounded-lg  text-sm px-5 py-3  mr-2 mb-5 my-1"
              >
                Breakdown time : {breakdown}
              </button>
            </div>

            {/* <div className="flex-1 flex flex-col">
              <button
                type="button"
                class="text-gray-900 flex bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-lime-300 dark:focus:ring-lime-800 shadow-lg shadow-lime-500/50 dark:shadow-lg dark:shadow-lime-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
              >
                Number of alerts
              </button>
              <button
                type="button"
                class="text-white flex bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
              >
                Total money saved
              </button>
              <button
                type="button"
                class="text-white flex bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 shadow-lg shadow-pink-500/50 dark:shadow-lg dark:shadow-pink-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
              >
                Active hours
              </button>
              <button
                type="button"
                class="text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-purple-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
              >
                Breakdown hours
              </button>
            </div> */}
          </div>

          {/* //button ends here           */}

          {/* <Best timeArr={relay1TimeArr} valueArr={relay1valueArr} /> */}
          {/* <Basicbar value={energyPerDay} date={energyPerDayDate} /> */}
        </div>
        <div className="flex-1 bg-gray-200 2xl:h-1/6 p-5 border-4 border-blue-600 shadow-indigo-500/50 m-2 rounded-lg shadow-lg">
          {/* energy/day */}
          {/* <Best timeArr={relay1TimeArr} valueArr={relay1valueArr} /> */}
          <div className="flex flex-col">
            <h1 className="text-center p-2 font-mono text-xl border-b-2 border-blue-600">
              ENERGY CONSUMPTION/DAY
            </h1>
            <h1 className="text-center p-2 font-mono">MONTH:{}</h1>
          </div>
          <Niku value={energyPerDay} date={energyPerDayDate} />
          {/* <Best valueArr={energyPerDay} timeArr={energyPerDayDate} /> */}
        </div>
      </div>

      {/* energy/minute grapgh */}
      <div className="flex flex-wrap">
        <div className="flex-1 bg-gray-200 2xl:h-1/6 p-5 border-4 border-blue-700 shadow-indigo-500/50 m-2 rounded-lg shadow-lg">
          <div className="flex flex-col">
            <h1 className="text-center p-2 font-mono text-xl border-b-2 border-blue-700">
              ENERGY/HOUR
            </h1>
            <h1 className="text-center p-2 font-mono">DAY:{selectedDate}</h1>
          </div>
          {/* <EnergyBar value={energydiff} time={timediff} /> */}
          <div className="w-full h-1/2">
            <Barfinal value={energydiff} time={timediff} />
          </div>
        </div>
      </div>

      {/* power trend and extra analytics flexbox */}
      <div className="flex flex-wrap">
        <div className="flex-1 bg-gray-200 2xl:h-1/6 p-5 border-4 border-blue-500 shadow-indigo-500/50 m-2 rounded-lg shadow-lg">
          <div className="flex flex-col">
            <h1 className="text-center p-2 font-mono text-xl border-b-2 border-blue-500">
              POWER/MINUTE
            </h1>
            <h1 className="text-center p-2 font-mono">DAY:{selectedDate}</h1>
          </div>
          <Basicline time={timeArray} value={powerArr} />
        </div>
        {/* <div className="flex-1 bg-gray-200 2xl:h-1/6 p-5  shadow-indigo-500/50 m-2 rounded-lg shadow-lg">
          <h2 className="text-center">Extra Analytics section</h2>
          <div className="flex-1 bg-yellow-200 2xl:h-1/6 p-5  shadow-indigo-500/15 m-2 rounded-lg shadow-lg flex flex-col">
            <div className="p-2 m-2 bg-slate-700 text-white rounded-lg">a</div>
            <div className="p-2 m-2 bg-slate-700 text-white rounded-lg">b</div>
            <div className="p-2 m-2 bg-slate-700 text-white rounded-lg">c</div>
            <div className="p-2 m-2 bg-slate-700 text-white rounded-lg">d</div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default StreetLight1;
