import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const data = [
  { x: new Date("2022-01-01").getTime(), y: 100 },
  { x: new Date("2022-02-01").getTime(), y: 200 },
  { x: new Date("2022-03-01").getTime(), y: 150 },
  { x: new Date("2022-04-01").getTime(), y: 300 },
  { x: new Date("2022-05-01").getTime(), y: 250 },
];

const Line = ({ date }) => {
  const [chartState, setChartState] = useState({
    series: [
      {
        name: "XYZ MOTORS",
        data: date,
      },
    ],
    options: {
      chart: {
        type: "area",
        stacked: false,
        height: 350,
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: "zoom",
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      title: {
        text: "Stock Price Movement",
        align: "left",
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return (val / 1000000).toFixed(0);
          },
        },
        title: {
          text: "Price",
        },
      },
      xaxis: {
        type: "datetime",
      },
      tooltip: {
        shared: false,
        y: {
          formatter: function (val) {
            return (val / 1000000).toFixed(0);
          },
        },
      },
    },
  });

  return (
    <div id="chart">
      <ReactApexChart
        options={chartState.options}
        series={chartState.series}
        type="area"
        height={350}
      />
    </div>
  );
};

export default Line;
