import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import ReactApexChart from "react-apexcharts";

const Basicline = ({ time, value }) => {
  //console.log(time);
  //console.log(value);
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Powers",
        data: value,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        zoom: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 0,
          options: {
            chart: {
              width: 50,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      stroke: {
        curve: "straight",
        width: 2,
      },
      title: {
        text: "POWER TREND",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#b4a7d6", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: time.map((t, i) => (i % 20 === 0 ? t : "")),
      },
    },
  });

  useEffect(() => {
    setChartData((prevState) => ({
      ...prevState,
      series: [
        {
          ...prevState.series[0],
          //data value
          data: value,
        },
      ],
      options: {
        ...prevState.options,
        xaxis: {
          ...prevState.options.xaxis,
          //time
          categories: time.map((t, i) => (i % 20 === 0 ? t : "")),
        },
      },
    }));
  }, [time, value]);

  return (
    <div id="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default Basicline;
