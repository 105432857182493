import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Main = () => {
  const Menus = [
    { title: "Dashboard", src: "Chart_fill" },
    { title: "Inbox", src: "Chat" },
    { title: "Accounts", src: "User", gap: true },
    { title: "Schedule ", src: "Calendar" },
    { title: "Search", src: "Search" },
    { title: "Analytics", src: "Chart" },
    { title: "Files ", src: "Folder", gap: true },
    { title: "Setting", src: "Setting" },
  ];
  const [open, setopen] = useState(true);

  const [imeiArr, setImeiArr] = useState([]);
  const [dataArr, setDataArr] = useState([]);
  const [ipmcbst, setipmcbst] = useState([]);
  const [contactor1, setcontactor1] = useState();
  const [contactor2, setcontactor2] = useState();
  const [contactor3, setcontactor3] = useState();
  const [opmcbst, setopmcbst] = useState();
  const [door, setdoor] = useState();
  const sendmsg = eval("(" + localStorage.getItem("sendmsg") + ")");

  useEffect(() => {
    const updateData = () => {
      const imeiArr = JSON.parse(localStorage.getItem("imeiArr")) || [];
      const dataArr = JSON.parse(localStorage.getItem("dataArr")) || [];

      const ipmcbst = JSON.parse(localStorage.getItem("ipmcbst"));
      const contactor1 = JSON.parse(localStorage.getItem("contactor1"));
      const contactor2 = JSON.parse(localStorage.getItem("contactor2"));
      const contactor3 = JSON.parse(localStorage.getItem("contactor3"));
      const opmcbst = JSON.parse(localStorage.getItem("opmcbst"));
      const door = JSON.parse(localStorage.getItem("door"));

      setImeiArr(imeiArr);
      setDataArr(dataArr);
      setipmcbst(ipmcbst);
      setcontactor1(contactor1);
      setcontactor2(contactor2);
      setcontactor3(contactor3);
      setopmcbst(opmcbst);
      setdoor(door);
    };

    updateData();
    const intervalId = setInterval(updateData, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      {/* <div>Imei-1: {imeiArr[0]}</div>
      <div>Imei-2:{imeiArr[1]}</div>
      <div>Imei-3:{imeiArr[2]}</div>
      <div>Imei-4:{imeiArr[3]}</div>
      <div>Imei-5:{imeiArr[4]}</div> */}

      <div className="text-2xl font-semibold ">
        <div className="flex bg-blue-900 justify-center">
          <h1 className="text-2xl md:text-xl font-semibold  text-center text-white bg-gradient-to-r from-blue-500 to-green-800 bg-clip-text p-3">
            STREET LIGHT CCMS
          </h1>
        </div>
      </div>

      <div className="flex flex-wrap">
        {/* sidebar starts here */}
        <div className="flex">
          <div
            className={` ${
              open ? "w-72 `&&` -mt-10" : "w-0 `&&` -ml-10"
            } bg-blue-900 h-screen p-5  pt-8 relative duration-300`}
          >
            {/* //img div */}
            <div>
              <img
                src=".///assets/control.png"
                className={`absolute cursor-pointer -right-3 top-5 w-7 border-dark-purple
          border-2 rounded-full  ${!open && "rotate-180"}`}
                alt="control"
                onClick={() => setopen(!open)}
              />
            </div>

            <div className="flex gap-x-4 items-center">
              <img
                src=".///assets/vidani.png"
                style={{ width: "50px", height: "50px" }}
                className={`cursor-pointer duration-500 ${
                  open && "rotate-[360deg]"
                }`}
                alt=""
              />

              <h1
                className={`text-white origin-left font-medium text-lg duration-500 ${
                  !open && "scale-0"
                } `}
              >
                Vidani
                <br />
                Automations
              </h1>
            </div>

            {/* //menus */}
            <ul className="pt-9">
              {Menus.map((menu, index) => (
                <li
                  key={index}
                  className={`text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-light-white rounded-md ${
                    menu.gap ? "mt-9" : "mt-2"
                  } 
              ${index === 0 && "bg-light-white"}
              `}
                >
                  <img src={`.///assets/${menu.src}.png`} alt="" />
                  <span
                    className={`${!open && "hidden"} origin-left duration-200`}
                  >
                    {menu.title}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* sidebar ends here */}

        {/* Card Div Starts here */}

        {/* SL1 DIV STARTS HERE:- COMMENT THIS CODE AFTERWORDS */}

        <div className="bg-slate-400 w-40 sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 p-5 m-3 rounded-lg shadow-2xl ease-in hover:shadow-4xl transition-shadow hover:shadow-stone-900 flex-1">
          <div className="border-b-4 border-indigo-500 mb-2">
            <h2 className="text-center font-semibold font-mono text-xl pb-3">
              <Link to="./StreetLight1.js">Imei:{imeiArr[5]}</Link>
            </h2>
          </div>

          <div className="flex flex-wrap justify-center items-center">
            <p className="p-2 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 ">
              VL<sub>1</sub>
              <p className="p-2 m-1 bg-red-400 hover:bg-red-600 hover:text-white rounded-xl shadow-lg hover:shadow-indigo-500/30 border border-indigo-500 hover:border-red-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][0] : "0"} V
              </p>
            </p>
            <p className="p-2 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1">
              VL<sub>2</sub>{" "}
              <p className="p-2 m-1 bg-yellow-300 hover:bg-yellow-400 hover:text-black rounded-xl shadow-xl border border-indigo-500 hover:border-yellow-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][1] : "0"} V
              </p>
            </p>
            <p className="p-2 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1">
              VL<sub>3</sub>{" "}
              <p className="p-2 m-1 bg-blue-400 hover:bg-blue-700 hover:text-white rounded-xl shadow-xl border border-indigo-500 hover:border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][2] : "0"} V
              </p>
            </p>
          </div>

          <div className="flex flex-wrap justify-center items-center ">
            <p className="p-2 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1">
              IL<sub>1</sub>{" "}
              <p className="p-2 m-1 bg-red-400 hover:bg-red-600 hover:text-white rounded-xl shadow-xl border border-indigo-500 hover:border-red-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][3] : "0"} A
              </p>
            </p>
            <p className="p-2 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1">
              IL<sub>2</sub>{" "}
              <p className="p-2 m-1 bg-yellow-300 hover:bg-yellow-400 hover:text-black rounded-xl shadow-xl border border-indigo-500 hover:border-yellow-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][4] : "0"} A
              </p>
            </p>
            <p className="p-2 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1">
              IL<sub>3</sub>{" "}
              <p className="p-2 m-1 bg-blue-400 hover:bg-blue-700 hover:text-white rounded-xl shadow-xl border border-indigo-500 hover:border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][5] : "0"} A
              </p>
            </p>
          </div>

          <div className="flex flex-wrap">
            <p className="p-3 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1">
              Power{" "}
              <p className="p-2 m-1 bg-green-500 hover:bg-green-600 hover:text-white rounded-xl shadow-xl border border-indigo-500 hover:border-green-700 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][6] : "0"}Kw
              </p>
            </p>
            <p className="p-3 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 ">
              Energy{" "}
              <p className="p-2 m-1 bg-green-500 hover:bg-green-600 hover:text-white rounded-xl shadow-xl border border-indigo-500 hover:border-green-700 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][10] : "0"}Kwh
              </p>
            </p>
          </div>

          <div className="flex flex-wrap">
            <p
              className={`flex flex-col items-center flex-1 p-2 m-1 justify-center  bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none text-black font-bold text-center   `}
            >
              RL1
              <button
                className={`flex items-center flex-1 p-2 m-1 justify-center rounded-full shadow-md ${
                  contactor1 > 500
                    ? "bg-green-400 text-white hover:bg-green-500"
                    : "bg-red-400 text-white hover:bg-red-500"
                } transition-colors ease-in-out duration-200`}
              >
                {contactor1 > 500 ? "ON" : "OFF"}
              </button>
            </p>
            <p
              className={`flex flex-col items-center flex-1 p-2 m-1 justify-center  bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none text-black font-bold text-center  `}
            >
              RL2{" "}
              <button
                className={`flex items-center flex-1 p-2 m-1 justify-center rounded-full shadow-md ${
                  contactor2 > 500
                    ? "bg-green-400 text-white hover:bg-green-500"
                    : "bg-red-400 text-white hover:bg-red-500"
                } transition-colors ease-in-out duration-200`}
              >
                {contactor2 > 500 ? "ON" : "OFF"}
              </button>
            </p>
            <p
              className={`flex flex-col items-center flex-1 p-2 m-1 justify-center  bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none text-black font-bold text-center  `}
            >
              RL3
              <button
                className={`flex items-center flex-1 p-2 m-1 justify-center rounded-full shadow-md ${
                  contactor3 > 500
                    ? "bg-green-400 text-white hover:bg-green-500"
                    : "bg-red-400 text-white hover:bg-red-500"
                } transition-colors ease-in-out duration-200`}
              >
                {contactor3 > 500 ? "ON" : "OFF"}
              </button>
            </p>
          </div>

          <div className="flex flex-wrap">
            <p
              className={`flex flex-col items-center flex-1 p-2 m-1 justify-center  bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none text-black font-bold text-center  `}
            >
              I/P MCB{" "}
              <button
                className={`flex items-center flex-1 p-2 m-1 justify-center rounded-full shadow-md ${
                  ipmcbst > 500
                    ? "bg-green-400 text-white hover:bg-green-500"
                    : "bg-red-400 text-white hover:bg-red-500"
                } transition-colors ease-in-out duration-200`}
              >
                {ipmcbst > 500 ? "ON" : "OFF"}
              </button>
            </p>
            <p
              className={`flex flex-col items-center flex-1 p-2 m-1 justify-center  bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none text-black font-bold text-center  `}
            >
              O/P MCB{" "}
              <button
                className={`flex items-center flex-1 p-2 m-1 justify-center rounded-full shadow-md ${
                  opmcbst > 500
                    ? "bg-green-400 text-white hover:bg-green-500"
                    : "bg-red-400 text-white hover:bg-red-500"
                } transition-colors ease-in-out duration-200`}
              >
                {opmcbst > 500 ? "ON" : "OFF"}
              </button>
            </p>
            <p
              className={`flex flex-col items-center flex-1 p-2 m-1 justify-center  bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none text-black font-bold text-center  `}
            >
              Door{" "}
              <button
                className={`flex items-center flex-1 p-2 m-1 justify-center rounded-full shadow-md ${
                  door > 500
                    ? "bg-green-400 text-white hover:bg-green-500"
                    : "bg-red-400 text-white hover:bg-red-500"
                } transition-colors ease-in-out duration-200`}
              >
                {door > 500 ? "Closed" : "Open"}
              </button>
            </p>
          </div>
        </div>

        {/* SL1 DIV ENDS HERE */}

        {/* SL2 DIV STARTS HERE :- COMMENT OTHERWISE */}
        <div className="bg-slate-400 w-40 sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 p-5 m-3 rounded-lg shadow-2xl ease-in hover:shadow-4xl transition-shadow hover:shadow-stone-900 flex-1 ">
          <div className="border-b-4 border-indigo-500 mb-2">
            <h2 className="text-center font-semibold font-mono text-xl pb-3">
              Imei:{imeiArr[5]}
            </h2>
          </div>

          <div className="flex flex-wrap justify-center items-center">
            <p className="p-2 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 ">
              VL<sub>1</sub>
              <p className="p-2 m-1 bg-red-400 hover:bg-red-600 hover:text-white rounded-xl shadow-lg hover:shadow-indigo-500/30 border border-indigo-500 hover:border-red-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][0] : "0"} V
              </p>
            </p>
            <p className="p-2 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1">
              VL<sub>2</sub>{" "}
              <p className="p-2 m-1 bg-yellow-300 hover:bg-yellow-400 hover:text-black rounded-xl shadow-xl border border-indigo-500 hover:border-yellow-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][1] : "0"} V
              </p>
            </p>
            <p className="p-2 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1">
              VL<sub>3</sub>{" "}
              <p className="p-2 m-1 bg-blue-400 hover:bg-blue-700 hover:text-white rounded-xl shadow-xl border border-indigo-500 hover:border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][2] : "0"} V
              </p>
            </p>
          </div>

          <div className="flex flex-wrap justify-center items-center ">
            <p className="p-2 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1">
              IL<sub>1</sub>{" "}
              <p className="p-2 m-1 bg-red-400 hover:bg-red-600 hover:text-white rounded-xl shadow-xl border border-indigo-500 hover:border-red-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][3] : "0"} A
              </p>
            </p>
            <p className="p-2 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1">
              IL<sub>2</sub>{" "}
              <p className="p-2 m-1 bg-yellow-300 hover:bg-yellow-400 hover:text-black rounded-xl shadow-xl border border-indigo-500 hover:border-yellow-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][4] : "0"} A
              </p>
            </p>
            <p className="p-2 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1">
              IL<sub>3</sub>{" "}
              <p className="p-2 m-1 bg-blue-400 hover:bg-blue-700 hover:text-white rounded-xl shadow-xl border border-indigo-500 hover:border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][5] : "0"} A
              </p>
            </p>
          </div>

          <div className="flex flex-wrap">
            <p className="p-3 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1">
              Power{" "}
              <p className="p-2 m-1 bg-green-500 hover:bg-green-600 hover:text-white rounded-xl shadow-xl border border-indigo-500 hover:border-green-700 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][6] : "0"}Kw
              </p>
            </p>
            <p className="p-3 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 ">
              Energy{" "}
              <p className="p-2 m-1 bg-green-500 hover:bg-green-600 hover:text-white rounded-xl shadow-xl border border-indigo-500 hover:border-green-700 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][7] : "0"}Kwh
              </p>
            </p>
          </div>

          <div className="flex flex-wrap">
            <p
              className={`flex flex-col items-center flex-1 p-2 m-1 justify-center  bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none text-black font-bold text-center   `}
            >
              RL1
              <button
                className={`flex items-center flex-1 p-2 m-1 justify-center rounded-full shadow-md ${
                  contactor1 > 500
                    ? "bg-green-400 text-white hover:bg-green-500"
                    : "bg-red-400 text-white hover:bg-red-500"
                } transition-colors ease-in-out duration-200`}
              >
                {contactor1 > 500 ? "ON" : "OFF"}
              </button>
            </p>
            <p
              className={`flex flex-col items-center flex-1 p-2 m-1 justify-center  bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none text-black font-bold text-center  `}
            >
              RL2{" "}
              <button
                className={`flex items-center flex-1 p-2 m-1 justify-center rounded-full shadow-md ${
                  contactor2 > 500
                    ? "bg-green-400 text-white hover:bg-green-500"
                    : "bg-red-400 text-white hover:bg-red-500"
                } transition-colors ease-in-out duration-200`}
              >
                {contactor2 > 500 ? "ON" : "OFF"}
              </button>
            </p>
            <p
              className={`flex flex-col items-center flex-1 p-2 m-1 justify-center  bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none text-black font-bold text-center  `}
            >
              RL3
              <button
                className={`flex items-center flex-1 p-2 m-1 justify-center rounded-full shadow-md ${
                  contactor3 > 500
                    ? "bg-green-400 text-white hover:bg-green-500"
                    : "bg-red-400 text-white hover:bg-red-500"
                } transition-colors ease-in-out duration-200`}
              >
                {contactor3 > 500 ? "ON" : "OFF"}
              </button>
            </p>
          </div>

          <div className="flex flex-wrap">
            <p
              className={`flex flex-col items-center flex-1 p-2 m-1 justify-center  bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none text-black font-bold text-center  `}
            >
              I/P MCB{" "}
              <button
                className={`flex items-center flex-1 p-2 m-1 justify-center rounded-full shadow-md ${
                  ipmcbst > 500
                    ? "bg-green-400 text-white hover:bg-green-500"
                    : "bg-red-400 text-white hover:bg-red-500"
                } transition-colors ease-in-out duration-200`}
              >
                {ipmcbst > 500 ? "ON" : "OFF"}
              </button>
            </p>
            <p
              className={`flex flex-col items-center flex-1 p-2 m-1 justify-center  bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none text-black font-bold text-center  `}
            >
              O/P MCB{" "}
              <button
                className={`flex items-center flex-1 p-2 m-1 justify-center rounded-full shadow-md ${
                  opmcbst > 500
                    ? "bg-green-400 text-white hover:bg-green-500"
                    : "bg-red-400 text-white hover:bg-red-500"
                } transition-colors ease-in-out duration-200`}
              >
                {opmcbst > 500 ? "ON" : "OFF"}
              </button>
            </p>
            <p
              className={`flex flex-col items-center flex-1 p-2 m-1 justify-center  bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none text-black font-bold text-center  `}
            >
              Door{" "}
              <button
                className={`flex items-center flex-1 p-2 m-1 justify-center rounded-full shadow-md ${
                  door > 500
                    ? "bg-green-400 text-white hover:bg-green-500"
                    : "bg-red-400 text-white hover:bg-red-500"
                } transition-colors ease-in-out duration-200`}
              >
                {door > 500 ? "Closed" : "Open"}
              </button>
            </p>
          </div>
        </div>
        {/* SL2 ENDS HERE */}

        {/* SL3 DIV STARTS HERE: keep only this div */}
        <div className="bg-slate-400 w-40 sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 p-5 m-3 rounded-lg shadow-2xl ease-in hover:shadow-4xl transition-shadow hover:shadow-stone-900 flex-1">
          <div className="border-b-4 border-indigo-500 mb-2">
            <h2 className="text-center font-semibold font-mono text-xl pb-3">
              Imei:{imeiArr[5]}
            </h2>
          </div>

          <div className="flex flex-wrap justify-center items-center">
            <p className="p-2 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 ">
              VL<sub>1</sub>
              <p className="p-2 m-1 bg-red-400 hover:bg-red-600 hover:text-white rounded-xl shadow-lg hover:shadow-indigo-500/30 border border-indigo-500 hover:border-red-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][0] : "0"} V
              </p>
            </p>
            <p className="p-2 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1">
              VL<sub>2</sub>{" "}
              <p className="p-2 m-1 bg-yellow-300 hover:bg-yellow-400 hover:text-black rounded-xl shadow-xl border border-indigo-500 hover:border-yellow-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][1] : "0"} V
              </p>
            </p>
            <p className="p-2 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1">
              VL<sub>3</sub>{" "}
              <p className="p-2 m-1 bg-blue-400 hover:bg-blue-700 hover:text-white rounded-xl shadow-xl border border-indigo-500 hover:border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][2] : "0"} V
              </p>
            </p>
          </div>

          <div className="flex flex-wrap justify-center items-center ">
            <p className="p-2 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1">
              IL<sub>1</sub>{" "}
              <p className="p-2 m-1 bg-red-400 hover:bg-red-600 hover:text-white rounded-xl shadow-xl border border-indigo-500 hover:border-red-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][3] : "0"} A
              </p>
            </p>
            <p className="p-2 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1">
              IL<sub>2</sub>{" "}
              <p className="p-2 m-1 bg-yellow-300 hover:bg-yellow-400 hover:text-black rounded-xl shadow-xl border border-indigo-500 hover:border-yellow-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][4] : "0"} A
              </p>
            </p>
            <p className="p-2 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1">
              IL<sub>3</sub>{" "}
              <p className="p-2 m-1 bg-blue-400 hover:bg-blue-700 hover:text-white rounded-xl shadow-xl border border-indigo-500 hover:border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][5] : "0"} A
              </p>
            </p>
          </div>

          <div className="flex flex-wrap">
            <p className="p-3 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1">
              Power{" "}
              <p className="p-2 m-1 bg-green-500 hover:bg-green-600 hover:text-white rounded-xl shadow-xl border border-indigo-500 hover:border-green-700 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][6] : "0"}Kw
              </p>
            </p>
            <p className="p-3 m-1 bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 ">
              Energy{" "}
              <p className="p-2 m-1 bg-green-500 hover:bg-green-600 hover:text-white rounded-xl shadow-xl border border-indigo-500 hover:border-green-700 transition duration-300 ease-in-out focus:outline-none block text-black font-bold ">
                {dataArr[5] ? dataArr[5][7] : "0"}Kwh
              </p>
            </p>
          </div>

          <div className="flex flex-wrap">
            <p
              className={`flex flex-col items-center flex-1 p-2 m-1 justify-center  bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none text-black font-bold text-center   `}
            >
              RL1
              <button
                className={`flex items-center flex-1 p-2 m-1 justify-center rounded-full shadow-md ${
                  contactor1 > 500
                    ? "bg-green-400 text-white hover:bg-green-500"
                    : "bg-red-400 text-white hover:bg-red-500"
                } transition-colors ease-in-out duration-200`}
              >
                {contactor1 > 500 ? "ON" : "OFF"}
              </button>
            </p>
            <p
              className={`flex flex-col items-center flex-1 p-2 m-1 justify-center  bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none text-black font-bold text-center  `}
            >
              RL2{" "}
              <button
                className={`flex items-center flex-1 p-2 m-1 justify-center rounded-full shadow-md ${
                  contactor2 > 500
                    ? "bg-green-400 text-white hover:bg-green-500"
                    : "bg-red-400 text-white hover:bg-red-500"
                } transition-colors ease-in-out duration-200`}
              >
                {contactor2 > 500 ? "ON" : "OFF"}
              </button>
            </p>
            <p
              className={`flex flex-col items-center flex-1 p-2 m-1 justify-center  bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none text-black font-bold text-center  `}
            >
              RL3
              <button
                className={`flex items-center flex-1 p-2 m-1 justify-center rounded-full shadow-md ${
                  contactor3 > 500
                    ? "bg-green-400 text-white hover:bg-green-500"
                    : "bg-red-400 text-white hover:bg-red-500"
                } transition-colors ease-in-out duration-200`}
              >
                {contactor3 > 500 ? "ON" : "OFF"}
              </button>
            </p>
          </div>

          <div className="flex flex-wrap">
            <p
              className={`flex flex-col items-center flex-1 p-2 m-1 justify-center  bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none text-black font-bold text-center  `}
            >
              I/P MCB{" "}
              <button
                className={`flex items-center flex-1 p-2 m-1 justify-center rounded-full shadow-md ${
                  ipmcbst > 500
                    ? "bg-green-400 text-white hover:bg-green-500"
                    : "bg-red-400 text-white hover:bg-red-500"
                } transition-colors ease-in-out duration-200`}
              >
                {ipmcbst > 500 ? "ON" : "OFF"}
              </button>
            </p>
            <p
              className={`flex flex-col items-center flex-1 p-2 m-1 justify-center  bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none text-black font-bold text-center  `}
            >
              O/P MCB{" "}
              <button
                className={`flex items-center flex-1 p-2 m-1 justify-center rounded-full shadow-md ${
                  opmcbst > 500
                    ? "bg-green-400 text-white hover:bg-green-500"
                    : "bg-red-400 text-white hover:bg-red-500"
                } transition-colors ease-in-out duration-200`}
              >
                {opmcbst > 500 ? "ON" : "OFF"}
              </button>
            </p>
            <p
              className={`flex flex-col items-center flex-1 p-2 m-1 justify-center  bg-gray-200 rounded-xl shadow-xl border-2 border-indigo-500 transition duration-300 ease-in-out focus:outline-none text-black font-bold text-center  `}
            >
              Door{" "}
              <button
                className={`flex items-center flex-1 p-2 m-1 justify-center rounded-full shadow-md ${
                  door > 500
                    ? "bg-green-400 text-white hover:bg-green-500"
                    : "bg-red-400 text-white hover:bg-red-500"
                } transition-colors ease-in-out duration-200`}
              >
                {door > 500 ? "Closed" : "Open"}
              </button>
            </p>
          </div>
        </div>
        {/* SL3 DIV ENDS HERE */}

        {/* card div ends here */}
      </div>
    </div>
  );
};

export default Main;
