import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js/auto";

Chart.register(CategoryScale);

const Best = ({ timeArr, valueArr }) => {
  //console.log("from best", timeArr);
  //console.log("from best", valueArr);

  const color = valueArr.map((value) => {
    if (value < 101) {
      return "#EF5350"; //yellow
    } else if (value >= 101 && value < 500) {
      return "#228B22"; // yellow;
    } else {
      return "#228B22";
      // return "#1E2F97";
    }
  });

  return (
    <div className="py-7 px-0 bg-white bg-opacity-20 rounded-lg shadow-lg backdrop-filter backdrop-blur-lg">
      <div className="flex flex-col sm:flex-row justify-center items-center">
        <div className="w-full h-full sm:h-1/2 md:h-full lg:h-full xl:h-full sm:w-1/2 md:w-full lg:w-full xl:w-full">
          <Bar
            data={{
              labels: timeArr.map((t, i) => (i % 1 === 0 ? t : "")),
              datasets: [
                {
                  label: "",
                  backgroundColor: color,
                  hoverBackgroundColor: color.map((c) => `${c}D9`), // lighter version of color for hover state
                  data: valueArr,
                  borderWidth: 2,
                  borderColor: color.map((c) => `${c}FF`),
                },
              ],
            }}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              scales: {
                x: {
                  type: "category",
                  offset: false,
                  ticks: {
                    autoSkip: false,
                    maxRotation: 90,
                    minRotation: 90,
                    font: {
                      size: 0,
                      weight: "bold",
                      color: "black",
                    },
                  },
                  grid: {
                    display: false,
                    color: "rgba(0, 0, 0, 0.1)",
                    borderWidth: 1,
                    borderColor: "rgba(0,0, 0.1)",
                  },
                },
                y: {
                  size: 100,
                  ticks: {
                    beginAtZero: true,
                    font: {
                      size: 0,
                      weight: "bold",
                      color: "black",
                    },
                  },
                  grid: {
                    display: true,
                    color: "rgba(0, 0, 0, 0.1)",
                    borderWidth: 1,
                    borderColor: "rgba(0, 0, 0, 0.1)",
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                  position: "bottom",
                  labels: {
                    // text: "ON",
                    font: {
                      size: 5,
                      weight: "bold",
                    },
                  },
                },
                title: {
                  display: true,
                  text: "STATUS VS TIME",
                  font: {
                    size: 15,
                  },
                },
                tooltips: {
                  enabled: true,
                  mode: "index",
                  intersect: false,
                  // callbacks: {
                  //   title: function (tooltipItem) {
                  //     const index = tooltipItem[0].index;
                  //     return timeArr[index];
                  //   },
                  //   label: function (tooltipItem) {
                  //     const index = tooltipItem.index;
                  //     return `Value: ${valueArr[index]}`;
                  //   },
                  // },
                },
              },
            }}
          />
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <div className="w-5 h-5 rounded-full bg-red-500 mr-2"></div>
        <span className="text-gray-500 font-medium mr-4">OFF</span>
        <div className="w-5 h-5 rounded-full bg-green-500 mr-2"></div>
        <span className="text-gray-500 font-medium">ON</span>
      </div>
    </div>
  );
};

export default Best;
