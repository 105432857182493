import React from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js/auto";
// import { pdata, timedata } from "./Get";
import "./App.css";

Chart.register(CategoryScale);

const Barfinal = ({ value, time }) => {
  return (
    <div className="bg-gray-100 py-7 px-0">
      {/* <h3 className="text-xl font-bold mb-4">Power Trend</h3> */}
      <div className="flex flex-col sm:flex-row justify-center items-center">
        <div className="w-full h-full sm:h-1/2 md:h-1/2 lg:h-1/2 xl:h-full sm:w-1/2 md:w-full lg:w-full xl:w-full">
          <Bar
            data={{
              labels: time.map((t, i) => (i % 1 === 0 ? t : "")),

              datasets: [
                {
                  label: "Power vs Time",
                  backgroundColor: "#6aa84f",
                  // hoverBackgroundColor: color.map(
                  //   (c) => `${c}D9` // lighter version of color for hover state
                  // ),

                  data: value,
                },
              ],
            }}
            options={{
              //   height: 1000,
              maintainAspectRatio: false,
              responsive: true,
              scales: {
                x: {
                  type: "category",
                  offset: true,
                  ticks: {
                    autoSkip: false,
                    maxRotation: 90,
                    minRotation: 90,
                    font: {
                      size: 14,
                      weight: "bold",
                    },
                  },
                  grid: {
                    display: false,
                    color: "rgba(0, 0, 0, 0.1)",
                    borderWidth: 1,
                    borderColor: "rgba(0,0, 0.1)",
                  },
                },
                y: {
                  ticks: {
                    beginAtZero: true,
                    font: {
                      size: 14,
                      weight: "bold",
                    },
                  },
                  //   height: 500,
                  grid: {
                    display: true,
                    color: "rgba(0, 0, 0, 0.1)",
                    borderWidth: 1,
                    borderColor: "rgba(0, 0, 0, 0.1)",
                  },
                },
              },
              plugins: {
                legend: {
                  display: true,
                  position: "bottom",
                  labels: {
                    font: {
                      size: 13,
                      weight: "bold",
                    },
                  },
                },
                title: {
                  display: false,
                  text: "Power Trend",
                },
              },
            }}
            height={500}
            width={null}
          />
        </div>
      </div>
    </div>
  );
};

export default Barfinal;
